/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

* {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5rem;
}


:root {
  --purple-dark: #8a7491;
  --purple-middle: #cb97c4;
  --purple-light: #ECD9EA;
  --green-dark:  #738e30;
}

.background {
  background-image: url('assets/img/background_wuenschmirwas.png');
  background-size:contain;
  background-attachment: fixed;
}

/* for all headlines on each site*/
.headline-content {
  font-family: 'Great Vibes', cursive;
  color: #8a7491;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3rem;
  font-stretch: wider;
}

a {
  color: #8a7491;
}

a:link {
  color: #8a7491;
}

a:hover {
  text-decoration: none;
}

a:visited {
  color: #8a7491;
}


::selection {
	color: #000000;
	background-color: #cecece;
}

/* for firefox */
::moz-selection {
	color: #000000;
	background-color: #cecece;
}


h1 {
  color: var(--purple-dark);
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;
  text-decoration: underline;
}

h2 {
  color: var(--purple-dark);
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;
}

a h2 {
  text-decoration-line: underline;
  text-decoration-color: --purple-middle !important;
}

a h2:hover {
  text-decoration: none;
}

h3 {
  margin: 2rem 0 0 0;
}